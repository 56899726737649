* {
  box-sizing: border-box;
}

.connectBtn {
  position: absolute;
  top: 40px;
  right: 20px;
}

.connectBtn > div > button {
  font-weight: 400 !important;
}

.imgContainer {
  width: 400px;
  height: 250px;
  /* background-color: pink; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  overflow: hidden;
}

.btnContainer {
  /* display: flex;
  flex-direction: column;
  flex-wrap: wrap; */
  /* background-color: black; */
  margin: 0;
  padding: 0;
}

.nft-button {
  border: none;
  outline: none;
  border-radius: 10%;
  background-color: inherit;
  /* height: 125px; */
  margin: 10px;
  /* width: 10%; */
  display: inline;
}

.nft-button[data-button-clicked='true'] {
  border: 2px solid black;
}

.nft-button:disabled {
  opacity: 0.3;
}

/* .nft-button:hover:disabled > img {
  cursor: not-allowed;
  pointer-events: all;
} */

/* .nft-button:not(:disabled) {
  border: 2px solid black;
} */

button:disabled {
  opacity: 0.5;
}

button:active {
  transform: translateY(4px);
}

/* .nft-button:not(:disabled):hover {
  transform: scale(1.04);
} */

.txSuccessPopUp,
.overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 2;
}

.overlay {
  background: rgba(14, 10, 10, 0.8);
}

.popUp {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: #f1f1f1;
  padding: 14px 28px;
  border-radius: 3px;
  max-width: 600px;
  min-width: 300px;
  /* padding-top: 50px; */
  z-index: 2;
  text-align: center;
}
.popUp > button {
  margin-top: 10px;
}

.chose {
  margin-bottom: 5px;
  color: #e40e0e;
  font-size: 17px;
  text-transform: uppercase;
}

.tokenId {
  border: 1px solid black;
  margin-bottom: 15px;
}

.tokenId:hover {
  cursor: pointer;
  background-color: bisque;
}

.tokenId:active {
  background-color: aquamarine;
}

.closeBtn {
  position: absolute;
  top: 7px;
  right: 5px;
  padding: 3px 2px;
  font-size: 16px;
}

.closeBtn:hover {
  background-color: #991e1e;
}
